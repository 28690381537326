import SelectBox from 'devextreme-react/select-box';
import { useAuth } from '../../contexts/auth';
import { schedulerGroups, updateUserDetails } from '../../api/api'

const GroupByBttn = props => {
    const { user, refreshSession } = useAuth();

    const updateSetting = e => {
        let updaterObj = {}
        updaterObj['scheduling_settings'] = props.userSettings
        updaterObj['scheduling_settings']['group_scheduler_by_provider'] = e.value

        updateUserDetails(
            user,
            updaterObj,
            (data) => {
                props.onUserSettingsUpdate(data.scheduling_settings)
            },
            (err) => console.log('err: ', err)
        )
    }

    return (
        <div className={props.className + ' dx-field-value'}>
            <SelectBox
                label="Group by..."
                labelMode="floating"
                placeholder=""
                height="40px"
                dataSource={schedulerGroups}
                displayExpr='Name'
                valueExpr="Value"
                // inputAttr={['one', 'two']}
                // dropDownButtonRender={'no'}
                onValueChanged={updateSetting}
            />
        </div>
    )
}

export default GroupByBttn