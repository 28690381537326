import React, { useState, useCallback, useRef, useEffect } from 'react';
import Drawer from 'devextreme-react/drawer';
import appInfo from '../../app-info';
import ScrollView from 'devextreme-react/scroll-view';
import { Header, SideNavigationMenu, Footer, Hero, IconBox,
  Title, ContactForm, SubscriptionPlan, FeatureBox } from '../../components';
import {useNavigate, useParams} from 'react-router-dom'
import { useScreenSize } from '../../utils/media-query';
import devices from 'devextreme/core/devices';
import { Template } from 'devextreme-react/core/template';
import { useMenuPatch } from '../../utils/patches';
import { InView } from "react-intersection-observer";
import './marketing.scss';


export default function Marketing({ title, description, children }) {
  const scrollViewRef = useRef(null);
  const navigate = useNavigate();
  const contactUsRef = useRef(null);
  const featuresRef = useRef(null);
  const aboutUsRef = useRef(null);
  const priceRef = useRef(null);
  const peekInsideRef = useRef(null);



  let { id } = useParams();
  const { isXSmall, isLarge } = useScreenSize();
  const [patchCssClass, onMenuReady] = useMenuPatch();
  const [menuStatus, setMenuStatus] = useState(MenuStatus.Closed);
  const [scrollOffset, setScrollOffset] = useState(null);
  const [fadeInAnimation, setFadeInAnimation] = useState("animate__animated animate__fadeIn animate__slow");
  const [fadeOutAnimation, setFadeOutAnimation] = useState("animate__animated animate__fadeOut animate__slow");


  useEffect(() => {
    (id === "contact-us") && contactUsRef.current.scrollIntoView(true);
    (id === "features") && featuresRef.current.scrollIntoView(true);
    (id === "about-us") && aboutUsRef.current.scrollIntoView(true);
    (id === "price") && priceRef.current.scrollIntoView(true);
    (id === "peek-inside") && peekInsideRef.current.scrollIntoView(true);
    (id === "legal") && console.log("it is legal")
  }, [id])

  const toggleMenu = useCallback(({ event }) => {
    setMenuStatus(
      prevMenuStatus => prevMenuStatus === MenuStatus.Closed
        ? MenuStatus.Opened
        : MenuStatus.Closed
    );
    event.stopPropagation();
  }, []);

  const temporaryOpenMenu = useCallback(() => {
    setMenuStatus(
      prevMenuStatus => prevMenuStatus === MenuStatus.Closed
        ? MenuStatus.TemporaryOpened
        : prevMenuStatus
    );
  }, []);

  const onOutsideClick = useCallback(() => {
    setMenuStatus(
      prevMenuStatus => prevMenuStatus !== MenuStatus.Closed && !isLarge
        ? MenuStatus.Closed
        : prevMenuStatus
    );
    return menuStatus === MenuStatus.Closed ? true : false;
  }, [isLarge, menuStatus]);

  const onNavigationChanged = useCallback(({ itemData, event, node }) => {
    if (menuStatus === MenuStatus.Closed || !itemData.path || node.selected) {
      event.preventDefault();
      return;
    }

    if (itemData.external) {
      window.open(itemData.path, '_blank').focus();
    } else {
      navigate(itemData.path);
    }

    scrollViewRef.current.instance.scrollTo(0);

    if (!isLarge || menuStatus === MenuStatus.TemporaryOpened) {
      setMenuStatus(MenuStatus.Closed);
      event.stopPropagation();
    }
  }, [navigate, menuStatus, isLarge]);

  const onScroll = useCallback((options) => {
    /*if (scrollOffset < options.scrollOffset.top) {
      console.log("going up");
      setFadeInAnimation("animate__animated animate__fadeInUp");
      setFadeOutAnimation("animate__animated animate__fadeOutUp");
    } else {
      console.log("going down");
      setFadeInAnimation("animate__animated animate__fadeInDown");
      setFadeOutAnimation("animate__animated animate__fadeOutDown");
    }*/
    setScrollOffset(options.scrollOffset.top);
  }, [scrollOffset]);

  return (
    <div className={'side-nav-outer-toolbar'}>
      <Header
        menuToggleEnabled
        toggleMenu={toggleMenu}
        title={title}
      />
      <Drawer
        className={['drawer', patchCssClass].join(' ')}
        position={'before'}
        closeOnOutsideClick={onOutsideClick}
        openedStateMode={isLarge ? 'shrink' : 'overlap'}
        revealMode={isXSmall ? 'slide' : 'expand'}
        minSize={isXSmall ? 0 : 0}
        maxSize={250}
        shading={isLarge ? false : true}
        opened={menuStatus === MenuStatus.Closed ? false : true}
        template={'menu'}
      >
        <div className={'container p-0'} style={{maxWidth: "100%"}}>
          <ScrollView ref={scrollViewRef} className={'layout-body with-footer'} onScroll={onScroll}>
            <Hero
              title="Effortless online scheduling"
              subtitle="Boost customer satisfaction with ezPlan: Online booking, scheduling and streamlined appointments."
              slogan=""
              bg_image_src="/hero.jpg"
              bg_video_src="hero.mp4"
              cta_link="/about/peek-inside"
              cta_label="Learn More"
              main_id="hero"
              cta_id="link_get_started"
            />

            <InView threshold={0.2} triggerOnce={true}>
              {({ inView, ref, entry }) => (
                <div ref={ref}>
                  <div className={"section about-2 p-0 pt-5 "+(inView?fadeInAnimation:fadeOutAnimation)} id="aboutFeatures">
                    <div className="container p-0" ref={featuresRef} >
                      <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center">
                        <div className="p-0" style={{maxWidth:"600px",  maxHeight: "50vh"}}>
                          <img style={{maxWidth:"600px", maxHeight: "50vh"}} src="/about.jpg" alt=""/>
                        </div>

                        <div className="p-3" style={{maxWidth:"600px", minWidth:"400px"}}>
                          <div className="row py-3">
                            <Title
                              regular_text="Intuitive"
                              highlighted_text="Features"
                            />
                          </div>
                          <ul className="marketing-list">
                            <li>Save time with easy and intelligent booking flow</li>
                            <li>Effortlessly manage your appointments with focused task list</li>
                            <li>Plan your day or week with a summarized schedule</li>
                            <li>Secure your appointments with online payments</li>
                            <li>Dedicated customer support</li>
                          </ul>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              )}
            </InView>


            <InView threshold={0.2} triggerOnce={true}>
              {({ inView, ref, entry }) => (
                <div ref={ref}>
                  <div ref={peekInsideRef} id="features" className={"section-bg section "+(inView?fadeInAnimation:fadeOutAnimation)}>
                    <div className="row py-5">
                      <div className="col-12">
                        <Title
                          regular_text="How it"
                          highlighted_text="Works"
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-row justify-content-center py-5">
                      {devices.current().deviceType === 'phone'?
                        <div className="px-1 w-100" className="ratio ratio-16x9">
                          <video controls>
                           <source src="/AdForHair.mp4" type="video/mp4"/>
                          </video>
                        </div>:
                        <div className="px-5 w-100" style={{maxWidth:"1000px"}}>
                          <video controls className="ratio ratio-16x9">
                           <source src="/AdForHair.mp4" type="video/mp4"/>
                          </video>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              )}
            </InView>

            <InView threshold={0.2} triggerOnce={true}>
              {({ inView, ref, entry }) => (
                <div ref={ref}>
                  <div ref={priceRef} id="price" className={"pb-5 section "+(inView?fadeInAnimation:fadeOutAnimation)}>
                    <div className="container">
                      <div className="row py-5">
                        <div className="col-12">
                          <Title
                            regular_text="Our"
                            highlighted_text="Pricing"
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-row justify-content-center">
                        <div style={{maxWidth:"600px", minWidth:"400px"}}>
                          <p className="text-center marketing-list p-3">We believe in easy to use apps and easy to understand prices. EzPlan is very affordable with a simple pricing model:
                          <br/><span className="dx-theme-accent-as-text-color">$0.20/appointment!</span> </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </InView>

            <InView threshold={0.2} triggerOnce={true}>
              {({ inView, ref, entry }) => (
                <div ref={ref}>
                  <div ref={contactUsRef} id="contact-us" className={"contact-us dx-theme-background-color "+(inView?fadeInAnimation:fadeOutAnimation)}>
                  	<div className="container">
                      <div className="row py-5">
                        <div className="col-12">
                          <Title
                            regular_text="Get In"
                            highlighted_text="Touch"
                          />
                        </div>
                      </div>
                  		<div className="row justify-content-center">
                  			<div className="col-12 col-lg-6">
                          <ContactForm customer_id='28eb8bb2-473c-4929-92d1-5f99690d954a'/>
                  			</div>
                  		</div>
                  	</div>
                  </div>
                </div>
              )}
            </InView>



            <div className="px-4">
              <Footer>
                Copyright © 2021-{new Date().getFullYear()} {appInfo.company}.
                <br />
                All trademarks or registered trademarks are property of their
                respective owners.
                <br />
                Version {appInfo.version}
              </Footer>
            </div>
          </ScrollView>
        </div>
        <Template name={'menu'}>
          <SideNavigationMenu
            compactMode={menuStatus === MenuStatus.Closed}
            selectedItemChanged={onNavigationChanged}
            openMenu={temporaryOpenMenu}
            onMenuReady={onMenuReady}
          >
          </SideNavigationMenu>
        </Template>
      </Drawer>
    </div>
  );

}

const MenuStatus = {
  Closed: 1,
  Opened: 2,
  TemporaryOpened: 3
};
