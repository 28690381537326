export function customizeNewDate (additionInHours=0) {
    let current = new Date()
    current.setHours(current.getHours()+additionInHours)
    const ct = new Date(current)
    return ct
}

export const injectDataInAppointment = (appointment, resources, services) => {
  const no_facility = {resource_type: "facility", resource_id: "00000000-0000-0000-0000-000000000000", resource_details: {name: 'No Facility'}};
  const no_provider = {resource_type: "provider", resource_id: "00000000-0000-0000-0000-000000000000", resource_details: {name: 'No Provider'}};
  let facility_found = false;
  let provider_found = false;
  let customer_found = false;

  for (let i = 0; i < resources.length; i++) {
    if (resources[i].resource_type === 'facility') {
      if (appointment.facility_id) {
        if (appointment.facility_id === "00000000-0000-0000-0000-000000000000") {
          appointment.facility = no_facility;
          facility_found = true;
        } else {
          if (appointment.facility_id === resources[i].resource_id) {
            appointment.facility = structuredClone(resources[i]);
            facility_found = true;
          }
        }
      } else {
        appointment.facility_id = "00000000-0000-0000-0000-000000000000";
        appointment.facility = no_facility;
        facility_found = true;
      }
    }
    if (resources[i].resource_type === 'provider') {
      if (appointment.provider_id) {
        if (appointment.provider_id === "00000000-0000-0000-0000-000000000000") {
          appointment.provider = no_provider;
          provider_found = true;
        } else {
          if (appointment.provider_id === resources[i].resource_id) {
            appointment.provider = structuredClone(resources[i]);
            provider_found = true;
          }
        }
      } else {
        appointment.provider_id = "00000000-0000-0000-0000-000000000000";
        appointment.provider = no_provider;
        provider_found = true;
      }
    }
    if (resources[i].resource_type === 'customer') {
      if (appointment.customer_id) {
        if (appointment.customer_id === resources[i].resource_id) {
          appointment.customer_data = structuredClone(resources[i])['resource_details'];
          customer_found = true;
        }
      } else {
        customer_found = true;
      }
    }
  }
  if ((facility_found === false) || (provider_found === false) || (customer_found === false)){
    return false
  }
  let svclst = [];
  for(let k = 0; k<appointment.appointment_type.length; k++) {
    let service_found = false;
    for (let i = 0; i < services.length; i++) {
      if (appointment.appointment_type[k]===services[i].service_id) {
        svclst.push(structuredClone(services[i]));
        service_found = true;
      }
    }
    if (service_found === false) {
      return false
    }
  }
  appointment.services = svclst;
  return true
}

export const parseHours = val => {
    let date
    let formatter
    let formattedTime
    if(val !== null) {
        date = new Date(val)
        formatter = new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit' });
        formattedTime = formatter.format(date);
    }
    return formattedTime
}

export const removeSeconds = (datestr) => {
    let s = datestr.split(":");
    let s2 = s[2]
    s2 = s2.split(" ")[1]
    return s[0]+":"+s[1]+" "+s2
}

export const convertRangeArrToFriendlyStr = arrayWithTwoNumbers => {
    return arrayWithTwoNumbers.toString().replace(',', '-')
}


export const parseServiceTitlesArr = (allServices, selectedServices) => {
    if(allServices && selectedServices) {
        const selService = allServices.filter(item => selectedServices.appointment_type.includes(item.service_id))
        return selService.map( item => item.details.service_title)
    }
}

export const getAppointmentPriceAndDuration = (allServices, appointment) => {
  let totalPrice = 0
  let totalDuration = 0

  for(let i in appointment.appointment_type) {
    const x = allServices.filter( s => s.service_id === appointment.appointment_type[i])
    totalPrice += x[0].details.price
    totalDuration += x[0].details.duration
  }
  return {
    "price": totalPrice,
    "duration": totalDuration,
  }
}

export const renderArrayAsReadableString = arrVal => {
    if(arrVal?.length > 1) {
        let titlesStr = ''
        for(let i=0; i<arrVal.length; i++) {
            if(i === (arrVal.length-1)){
              titlesStr += arrVal[i]
            } else if(i === (arrVal.length-2)) {
              titlesStr += arrVal[i]
              titlesStr += ' and '
            } else {
              titlesStr += arrVal[i]
              titlesStr += ', '
            }
        }
        return titlesStr
    }
    if(arrVal?.length === 1) {
      return arrVal[0]
    }
}

export const renderServiceTitles = (allServices, appointment) => {
    const res = parseServiceTitlesArr(allServices, appointment)
    const humanReadableString = renderArrayAsReadableString(res)
    return humanReadableString
}

export const calculatePrices = (pricing) => {
  if (!pricing) {
    return null
  }
  let quote = 0;
  let paid = 0;
  let due = 0;
  if (pricing.quoted_cash_price) {
    quote = pricing.quoted_cash_price
    due = quote
  }
  if (pricing.amount_paid_by_card) {
    paid = pricing.amount_paid_by_card
    due = quote - paid
  }
  return {
    'quote': quote,
    'paid': paid,
    'due': due
  }
}


export const debounce = (func, timeout = 300) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}
