export const navigation = [
  {
    text: 'Home',
    path: '/home',
    icon: 'fas fa-home'
  },
  {
    text: 'Facilities',
    path: '/facilities',
    icon: 'far fa-building'
  },
  {
    text: 'Team',
    path: '/providers',
    icon: 'far fa-id-card'
  },
  {
    text: 'Customers',
    path: '/customers',
    icon: 'fas fa-user'
  },
  {
    text: 'Schedule',
    path: '/schedule',
    icon: 'fas fa-calendar-alt'
  },
  {
    text: 'Services',
    path: '/services',
    icon: 'fas fa-concierge-bell'
  },
  {
    text: 'Settings',
    path: '/profile',
    icon: 'fas fa-cogs'
  },
  {
    text: 'Billing',
    path: '/billing',
    icon: 'far fa-credit-card'
  },
  {
    text: 'Legal',
    icon: 'fas fa-info-circle',
    items: [
      {
        text: 'Terms of Service',
        path: '/legal/tos'
      },
      {
        text: 'Privacy Policy',
        path: '/legal/pp'
      }
    ]
  }
];

export const navigationAdmin = [
  {
    text: 'Admin Help',
    path: '/admin-help',
    icon: 'far fa-question-circle'
  },
];

export const navigationUnauthenticated = [
  {
    text: 'Features',
    path: '/about/features',
    icon: 'fas fa-sitemap'
  },
  {
    text: 'How it Works',
    path: '/about/peek-inside',
    icon: 'fas fa-photo-video'
  },
  {
    text: 'Pricing',
    path: '/about/price',
    icon: 'fas fa-file-invoice-dollar'
  },
  {
    text: 'Contact Us',
    path: '/about/contact-us',
    icon: 'fas fa-address-card'
  },
  {
    text: 'Legal',
    icon: 'fas fa-info-circle',
    items: [
      {
        text: 'Terms of Service',
        path: '/legal/tos'
      },
      {
        text: 'Privacy Policy',
        path: '/legal/pp'
      }
    ]
  }
];
