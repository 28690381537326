import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router';
import './Profile.scss';
import {Form,
  Label,
  SimpleItem,
  EmptyItem,
  GroupItem,
  ButtonItem,
  RequiredRule,
  EmailRule} from 'devextreme-react/form';
import FileUploader from 'devextreme-react/file-uploader';
import { useAuth } from '../../../contexts/auth';
import {uploadFileChunk} from '../../../api/s3'
import {updateUserAttribute} from '../../../api/auth'
import {getUserDetails, updateUserDetails} from '../../../api/api'
import { Tooltip } from 'devextreme-react/tooltip';
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';
import Button from 'devextreme-react/button';
import {
  Validator,
  StringLengthRule,
} from 'devextreme-react/validator';
import { BookService } from '../../../components';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import notify from 'devextreme/ui/notify';
import devices from 'devextreme/core/devices';

const isPhone = devices.current().deviceType === 'phone';

export default function Profile() {
  const formRef = useRef(null);
  const templateTitleRef = useRef(null);
  const fileExtensions = ['.jpg', '.jpeg', '.png'];
  const { user, refreshSession } = useAuth();
  const [profileData, setProfileData] = useState(null);
  const [newProfileData, setNewProfileData] = useState(null);
  const [showPopupEditProfile, setShowPopupEditProfile] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const onSave = async () => {
    setSaveButtonOptions({
      disabled: true,
      text: 'Save',
      type: 'default',
      width: '100%',
      onClick: onSave,
    });

    var al = [];
    var newUD = {};
    var needsUpdate = false;
    for (const [key, value] of Object.entries(newProfileData)) {
      if (key === 'owner_id') {
        continue;
      }
      if (key === 'sub') {
        continue;
      }
      if ((key === 'name') || (key === 'email'))  {
        if (value !== profileData[key]) {
          al.push({
          	Name: key,
          	Value: value,
          })
        }
        continue;
      }
      if ((key === 'phone') && (value)) {
        if (value !== profileData[key]) {
          if (value.startsWith("+")) {
            al.push({
            	Name: 'phone_number',
            	Value: value,
            })
          } else {
            if (value.length) {
              al.push({
              	Name: 'phone_number',
              	Value: "+"+value,
              })
            } else {
              al.push({
              	Name: 'phone_number',
              	Value: value,
              })
            }
          }
        }
        continue;
      }
      if (key === 'image') {
        if (value !== profileData[key]) {
          al.push({
          	Name: 'picture',
          	Value: value,
          })
        }
        continue;
      }
      if (value !== profileData[key]) {
        newUD[key] = value;
        needsUpdate = true;
      }
    }


    if (al.length > 0) {
      console.log(al);
      try {
        const result = await updateUserAttribute(user, al);
        if (result.isOk) {
          refreshSession();
          setNewProfileData(null);
          setProfileData(null);
        } else {
          notify(result.message, 'error', 2000);
        }
      } catch (error) {
        notify(error.message, 'error', 2000);
      }
    }
    if (needsUpdate) {
      console.log(newUD);
      updateUserDetails(user, newUD, onUpdateUserDetailsSuccess, onError)
    } else {
      if (searchParams.get('setupMode')) {
        navigate('/home?setupMode=true');
      }
    }

  }

  const onCancel = () => {
    setNewProfileData(null);
    setShowPopupEditProfile(false);
  }

  /*
  () => {
    //
  }
  */


  const [cancelButtonOptions, setCancelButtonOptions] = useState({
    disabled: false,
    text: 'Cancel',
    width: '100%',
    onClick: onCancel,
  });

  const [saveButtonOptions, setSaveButtonOptions] = useState({
    disabled: true,
    text: 'Save',
    type: 'default',
    width: '100%',
    onClick: onSave,
  });

  const copyLinkButtonOptions = {
    text: 'copy',
    icon: 'far fa-clone',
    type: 'default'
  };

  useEffect(() => {
    if (user.signInUserSession) {
      getUserDetails(user, onGetUserDetailsSuccess, onError);
    }
  }, [user && user.signInUserSession, user.signInUserSession.idToken.payload]);

  const onError = useCallback((error) => {
    if (error.status === 401) {
      refreshSession();
    } else if (error.status === 409) {
      notify("There is already a profile using this company name.", 'error', 5000);
    }
    else {
      notify("An error has occured. Please try again later.", 'error', 2000);
      console.log(error);
    }
  }, [refreshSession]);

  const onGetUserDetailsSuccess = useCallback((data) => {
    setProfileData(data);

    setShowPopupEditProfile(false);
    setNewProfileData(null);
    if (searchParams.get('setupPopup') === 'profile') {
      setShowPopupEditProfile(true);
      let new_data = Object.assign({}, data);
      setNewProfileData(new_data);
    }
  }, [])

  const onUpdateUserDetailsSuccess = useCallback((data) => {
    setProfileData(data);
    setShowPopupEditProfile(false);
    setNewProfileData(null);
    if (searchParams.get('setupMode')) {
      navigate('/home?setupMode=true');
    }
  }, [])

  const onCloseEditProfileDialog = () => {
    setShowPopupEditProfile(false);
  }

  const onCompanyTitleChanged = (e) => {
    //console.log(e.event.currentTarget.value);
    setCancelButtonOptions({
      disabled: (!(e.event.currentTarget.value)) && (!(profileData.company_name)),
      text: 'Cancel',
      width: '100%',
      onClick: onCancel,
    });
    setSaveButtonOptions({
      disabled: (!(e.event.currentTarget.value)),
      text: 'Save',
      type: 'default',
      width: '100%',
      onClick: onSave,
    });
  }

  const companyTitleChangedOptions = {
    onInput: onCompanyTitleChanged,
  };


  const phoneEditorOptions = { mask: '(000) 000-0000' };

  const onUploadChunk = (file, uploadInfo) => {
    return uploadFileChunk(file, uploadInfo, {path:'images/'+profileData.sub});
  }

  const onUploaded = (e) => {
    let data = {...newProfileData};
    if (data === null) {
      data = {}
    }
    data.image = "https://s3.us-east-2.amazonaws.com/com.wdttx.scheduler/images/"+profileData.sub+"/"+encodeURIComponent(e.file.name).replace(/%20/g,'+');
    setNewProfileData(data)
  }

  useEffect(() => {
    if (newProfileData) {
      setCancelButtonOptions({
        disabled: !(newProfileData.company_name),
        text: 'Cancel',
        width: '100%',
        onClick: onCancel,
      });
      setSaveButtonOptions({
        disabled: !(newProfileData.company_name),
        text: 'Save',
        type: 'default',
        width: '100%',
        onClick: onSave,
      });
    }
  }, [newProfileData, profileData]);



  return (
    <>
    {profileData && <div className="content-block">
      <div className="d-flex flex-row justify-content-between align-items-center ">
        <h2 className={''}>Profile</h2>
        <Button
          type="default"
          text="Edit"
          onClick={() => {
            setNewProfileData({...profileData});
            setShowPopupEditProfile(true);
          }}
        />
      </div>
      <hr className="mt-0 mb-5"></hr>
      <div className="d-flex flex-column">
        <div className="">
          <div  className="d-flex flex-row justify-content-start align-items-start">
            <TextBox
              readOnly={true}
              label="Public Link"
              labelMode="static"
              width="100%"
              value={window.location.protocol+"//"+window.location.host+"/book/"+profileData.company_profile}
              >
            </TextBox>
            <Button
              className="ms-2"
              icon="far fa-clone"
              id="copybutton"
              type="default"
              stylingMode="text"
              onClick={() => {
                navigator.clipboard.writeText(window.location.protocol+"//"+window.location.host+"/book/"+profileData.company_profile);
              }}
            >

            </Button>
            <Tooltip
              target="#copybutton"
              showEvent="click"
              hideEvent={{
                  name: "click",
                  delay: 1500
              }}
            >
              Copied
            </Tooltip>
          </div>
          <p className="dx-field-label w-100" style={{whiteSpace:"normal"}}>This is the public link that your customers will use when booking an appointment with you. You can send it directly to them or embed it in your website.</p>
        </div>
      </div>
      {profileData && false && <div className={'content-block dx-card responsive-paddings d-flex flex-column align-items-center'}>

        <BookService profileData={profileData}/>
      </div>}

      <Popup
        className="text-danger"
        visible={showPopupEditProfile}
        onHidden={onCloseEditProfileDialog}
        dragEnabled={false}
        showCloseButton={false}
        fullScreen={isPhone}
        showTitle={true}
        title="Edit Profile"
        container=".dx-viewport"
        width={380}
        height={680}
      >
        <Position at="center" my="center"collision="fit" />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="before"
          options={saveButtonOptions}
        />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="after"
          options={cancelButtonOptions}
        />

        {newProfileData && <div className="">

          <div className={'d-flex flex-column align-items-center'}>
            { newProfileData && (newProfileData.image || newProfileData.image) &&
              <div className={'text-center form-avatar'}>
              {newProfileData && newProfileData.image ? <img
                alt={''}
                src={newProfileData.image}
              />:
              <img
                alt={''}
                src={newProfileData.image}
              />
            }
            </div>}
            <div className="d-flex text-center" style={{width: "220px"}}>
              <FileUploader
                selectButtonText="Update Logo"
                labelText=""
                multiple={false}
                chunkSize={6000000}
                uploadChunk={onUploadChunk}
                onUploaded={onUploaded}
                allowedFileExtensions={fileExtensions}
              />
            </div>

          </div>
          <Form
            ref={formRef}
            id={'form'}
            formData={newProfileData}
            labelLocation={'top'}
          >
            <SimpleItem dataField="company_name" editorOptions={companyTitleChangedOptions}>
              <RequiredRule message="Company name is required" />
            </SimpleItem>
            {false && <SimpleItem dataField="email">
              <RequiredRule message="Email is required" />
              <EmailRule message="Email is invalid" />
            </SimpleItem>}
            <SimpleItem
                dataField="phone"
                editorOptions={phoneEditorOptions}
            />
            <SimpleItem
                dataField="cta_text"
                helpText="You can configure the text of the main button that is publicly visible to your customers. It should be something like: Book Now or Schedule Now"
            >
              <Label text="Call-to-Action Text"/>
            </SimpleItem>
            <EmptyItem/>
          </Form>
        </div>}
      </Popup>

    </div>}

    </>
  );
}
