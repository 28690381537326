import React, { useState, useEffect, useRef, useCallback } from 'react';
import 'devextreme/data/odata/store';
import FileUploader from 'devextreme-react/file-uploader';

import DataGrid, {
  Column,
  Pager,
  Paging,
  FilterRow,
  SearchPanel,
  Editing,
  Button as DGButton,
  Lookup,
} from 'devextreme-react/data-grid';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import Button from 'devextreme-react/button';
import {
  Form,
  Label,
  SimpleItem,
  EmptyItem,
  GroupItem,
  ButtonItem,
  RequiredRule,
  EmailRule
} from 'devextreme-react/form';
import { useAuth } from '../../contexts/auth';
import { getResources, updateResource, deleteResource } from '../../api/api'
import { uploadFileChunk } from '../../api/s3'
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup';

export default function Drivers() {
  const { user, refreshSession } = useAuth();
  const [customersList, setCustomersList] = useState([]);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [loading, setLoading] = useState(true)
  const fileExtensions = ['.jpg', '.jpeg', '.png'];
  const dataGridRef = useRef(null);

  useEffect(() => {
    if (user.signInUserSession) {
      getResources(user, onGetResourcesSuccess, onError);
    }
  }, [user && user.signInUserSession, user.signInUserSession.idToken.payload]);


  const onError = useCallback((error) => {
    console.log('error occured')
    if (error.status === 401) {
      refreshSession();
    } else {
      console.log(error);
    }
  }, [refreshSession]);

  const onSaveSuccess = useCallback((data) => {
    setCurrentCustomer(null);
  }, [])

  const onGetResourcesSuccess = useCallback((data) => {
    let lst = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i].resource_type === 'customer') {
        lst.push(data[i])
      }
    }
    setCustomersList(lst);
    setLoading(false)
  }, [])

 
  const insertRow = e => {
    const obj = {}
    obj.resource_id = crypto.randomUUID()
    obj.resource_details = e.data.resource_details
    obj.resource_details.name = e.data.resource_details.name.trim()
    obj.resource_details.email = e.data.resource_details.email.trim()
    obj.resource_details.notes = e.data.resource_details.notes.trim()
    obj.resource_type = 'customer'

    console.log('insert object', e.data.resource_details)

    updateResource(user, obj, onSaveSuccess, onError);
  }

  const updateRow = e => {
    e.data.resource_details.name = e.data.resource_details.name.trim()
    e.data.resource_details.email = e.data.resource_details.email.trim()
    e.data.resource_details.notes = e.data.resource_details.notes.trim()
    updateResource(user, e.data, onSaveSuccess, onError);
  }

  const removeRow = e => {
    deleteResource(user, e.key, onSaveSuccess, onError)
  }

  const phoneEditorOptions = { mask: '(000) 000-0000' };


  if(!loading) {
    return (
      <React.Fragment>
        <div className="d-flex flex-row justify-content-between align-items-center content-block">
          <h2 className={''}>My Customers</h2>
          <Button
            type="default"
            text="Add Customer"
            onClick={() => {
              dataGridRef.current?.instance.addRow()
            }}
          />
        </div>

        <DataGrid
          ref={dataGridRef}
          className={'dx-card wide-card'}
          dataSource={customersList}
          showBorders={false}
          focusedRowEnabled={false}
          defaultFocusedRowIndex={0}
          columnAutoWidth={true}
          columnHidingEnabled={true}
          onRowInserted={insertRow}
          onRowUpdated={updateRow}
          onRowRemoved={removeRow}
        >
        
          <Editing
            mode="row"
            allowUpdating={true}
            allowDeleting={true}
            // allowAdding={true} 
          />

          <Paging defaultPageSize={50} />
          <Pager showPageSizeSelector={true} showInfo={true} />
          <SearchPanel
            visible={true}
            width="250"
            placeholder="Search..."
          />

          <Column
            dataField={'resource_details.name'}
            caption={'Name'}
            hidingPriority={3}
          >
            <RequiredRule />
          </Column>
          <Column
            dataField={'resource_details.email'}
            caption={'Email'}
            hidingPriority={1}
          />
          <Column
            dataField={'resource_details.phone'}
            caption={'Phone'}
            hidingPriority={0}
            editorOptions={phoneEditorOptions}
          >
            <RequiredRule />
          </Column>
          <Column
            dataField={'resource_details.notes'}
            editorType="dxTextArea"
            caption={'Internal notes'}
            hidingPriority={0}
          />     
        </DataGrid>

      </React.Fragment>
    )
  } else {
    return (
      <div className="d-flex flex-row justify-content-center align-items-center mt-5 pt-5">
          <LoadIndicator
              id="large-indicator"
              height={60}
              width={60}
          />
      </div>
    )
  }
}
