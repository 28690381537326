import React, { useCallback } from "react";
import { Button } from 'devextreme-react/button';
import './FacilityCard.scss'
import {useNavigate} from 'react-router-dom'
import { confirm } from "devextreme/ui/dialog"

export default function FacilityCard(props) {
    const userData = null;
    const navigate = useNavigate();

    const isCustomerFacility = props.facility.resource_id == "00000000-0000-0000-0000-000000000001"

    const renderCapacityValue = useCallback( () => {
      const capacity = props.facility?.resource_details?.capacity
      if(capacity) {
        let description = 'Up to '
        if(capacity === 1){
          description += capacity + ' customer'
        } else {
          description += capacity + ' customers'
        }
        return description
      }
    }, [])

    return (
      <div className="dx-card vehicle-card d-flex flex-column align-items-center h-100">
        <div className={'pt-2 d-flex flex-column align-items-center'}>
          <div className={'vehicle-card-image'}>
            {(props.facility.resource_details && props.facility.resource_details.picture) ? <img
              alt={''}
              src={props.facility.resource_details.picture}
            />:
            <img
              alt={''}
              src={'/DefaultFacility.png'}
            />}
          </div>
        </div>

        <h5 className="pt-1 ms-5 w-100">{props.facility.resource_details.name}</h5>
        <p className="ms-5 w-100 text-muted">{renderCapacityValue()}</p>
        <hr className="vehicle-card-divider"/>
        <div className="my-2" >
          <Button
            className="mx-2"
            type="normal"
            icon={props.facility.resource_details.active?"fas fa-pause":"fas fa-play"}
            stylingMode="outlined"
            text={props.facility.resource_details.active?"Suspend":"Resume"}
            disabled={props.loading || isCustomerFacility}
            onClick={() => {props.onSuspendResume(props.facility);}}/>
          <Button
            className="mx-2"
            type="normal"
            icon="fas fa-pen"
            stylingMode="outlined"
            text="Edit"
            disabled={props.loading || isCustomerFacility}
            onClick={() => {props.onEdit(props.facility);}}/>

          <Button
            className="mx-2"
            type="danger"
            icon="far fa-trash-alt"
            stylingMode="outlined"
            text="Delete"
            disabled={props.loading || isCustomerFacility}
            onClick={(e) => {
                console.log(e)
                let result = confirm("<i>Are you sure?</i>", "Confirm deletion");
                result.then(dialogResult => dialogResult && props.onDelete(props.facility) )
              }
            }
            />
        </div>
      </div>
    );
}
