import React from "react";
import { Button } from 'devextreme-react/button';
import './ScheduleDataTooltip.scss'
import {useNavigate} from 'react-router-dom'
import { renderServiceTitles, calculatePrices } from "../../utils/miscUtil";

export default function ScheduleDataTooltip(props) {
    const userData = null;
    const navigate = useNavigate();
    const serviceTitles = renderServiceTitles(props.data.appointmentData.services, props.data.appointmentData)
    const prices = calculatePrices(props.data.appointmentData.pricing)

    function prettyDate2(time) {
      var date = new Date(time);
      return date.toLocaleTimeString(navigator.language, {
        hour: '2-digit',
        minute:'2-digit'
      });
    }

    return (
      <>
        <div className="p-2 d-flex flex-column align-items-start justify-content-between schedule-data-tooltip">
          <h5>{serviceTitles ? serviceTitles : 'loading...'}</h5>

          <p className=""><i className="fas fa-clock pe-1"></i><b>{prettyDate2(props.data.appointmentData.startDate)} - {prettyDate2(props.data.appointmentData.endDate)}</b></p>
          {props.data.appointmentData.details && <p className="pb-2"><i className="fas fa-info-circle pe-1"></i> {props.data.appointmentData.details.notes}</p>}
          {props.data.appointmentData.customer_data && <p className="py-2">
            <i className="far fa-address-card pe-1"></i><b>{props.data.appointmentData.customer_data.name}</b> <span className="mx-2"> - </span> {props.data.appointmentData.customer_data.email} <span className="mx-2"> - </span> {props.data.appointmentData.customer_data.phone} <span className="mx-2"> - </span> {props.data.appointmentData.customer_data.notes}
          </p>}
          {prices && <p>Quoted price: <b>${prices.quote}</b></p>}
          {prices && <p>Amount paid: <b>${prices.paid}</b></p>}
          {prices && <p>Amount due: <b>${prices.due}</b>+tax</p>}
          <div className="d-flex flex-row mt-2">
            <div
              style={{
                backgroundColor: "#fff",
                backgroundImage: `url("${ props.data.appointmentData.provider?.resource_details.picture ? props.data.appointmentData.provider.resource_details.picture : '/DefaultAvatar.png'}")`,
                backgroundSize: 'cover',
                width: "50px",
                height: "50px",
                borderRadius: "50px",
              }}
            />
            <div
              className="ms-1"
              style={{
                backgroundColor: "#fff",
                backgroundImage: `url("${ props.data.appointmentData.facility?.resource_details.picture ? props.data.appointmentData.facility.resource_details.picture : '/DefaultFacility.png'}")`,
                backgroundSize: 'contain',
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: "50px",
                height: "50px",
                borderRadius: "2px",
              }}
            />
          </div>
        </div>

      {/* {false && props.data.data.resource_type === "facility" &&
        <div className="d-flex flex-row align-items-center justify-content-start">
          <div className={'vehicle-card-image-xs'}>
            {(props.data.appointmentData.details && props.data.appointmentData.details.picture) ? <img
              alt={''}
              src={props.data.details.appointmentData.picture}
            />:
            <img
              alt={''}
              src={'/DefaultFacility.png'}
            />}
          </div>
          <p className="ps-1 m-0">{props.data.appointmentData.details.name}</p>
        </div>
      } */}
      </>
    );
}
